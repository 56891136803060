// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';

import tinycolor from 'tinycolor2';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { formatNumber, FormatOptions } from '@/utilities/numberHelper.utilities';
import { isStringSeries } from '@/utilities/utilities';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import i18next from 'i18next';
import {
  DEFAULT_BACKGROUND_DARK,
  DEFAULT_BACKGROUND_LIGHT,
  DEFAULT_TEXT_COLOR_DARK,
  DEFAULT_TEXT_COLOR_LIGHT,
  STRIPED_CELL_COLOR,
  STRIPED_CELL_COLOR_DARK,
  TableBuilderHeaderType,
} from '@/tableBuilder/tableBuilder.constants';
import { ANALYSIS_COLORS } from '@/trend/trendViewer/trendViewer.constants';
import { TableColumn } from '@/tableBuilder/tableBuilder.store';

export const NULL_PLACEHOLDER = '-';
const isReadableColorCache = new Map();

/**
 * Formats the text for a column header.
 *
 * @param headerInfo - Header formatting information
 * @param headerInfo.type - One of TableBuilderHeaderType enumeration
 * @param headerInfo.format - Format string to use if .type is a format involving date/times
 * @param property - Custom property
 * @param startTime - Start time for the cell
 * @param endTime - End time for the cell
 * @param timezone - timezone to run the table for
 * @returns The formatted header
 */
export function formatHeader(
  headerInfo: { type: TableBuilderHeaderType; format: string },
  property: string | undefined,
  startTime: number,
  endTime: number,
  timezone: { name: string },
): string {
  const formatDate = (date, isStart) =>
    _.isNil(date)
      ? i18next.t(`TABLE_BUILDER.${isStart ? 'STARTS_OUT_OF_RANGE' : 'ENDS_OUT_OF_RANGE'}`)
      : moment.utc(date).tz(timezone.name).format(headerInfo.format);
  if (headerInfo.type === TableBuilderHeaderType.None) {
    return '';
  } else if (headerInfo.type === TableBuilderHeaderType.CapsuleProperty) {
    return property ?? '';
  } else if (headerInfo.type === TableBuilderHeaderType.Start) {
    return formatDate(startTime, true);
  } else if (headerInfo.type === TableBuilderHeaderType.End) {
    return formatDate(endTime, false);
  } else {
    return `${formatDate(startTime, true)} - ${formatDate(endTime, false)}`;
  }
}

/**
 * Formats a metric value for display in the scorecard table.
 *
 * @param value - The value of the metric
 * @param formatOptions - the format options to be used when formatting the cell
 * @param [column] - One of the columns from COLUMNS
 * @returns The formatted value
 */
export function formatMetricValue(value: any, formatOptions: FormatOptions, column: any = {}): string {
  if (_.isNil(value)) {
    return column.style === 'string' ? '' : NULL_PLACEHOLDER;
  } else if (_.isNumber(value)) {
    return formatNumber(
      value,
      {
        ...formatOptions,
        format: column.format || formatOptions?.format,
      },
      _.noop,
    );
  } else {
    return value;
  }
}

/**
 * Computes the style for cell.
 *
 * @param backgroundColor - Background color.
 * @param textColor - Text color
 * @param textStyle - An array with text styles
 * @param textAlign - Text align
 * @param priorityBackgroundColor - Overwrites the backgroundColor.
 * @param fallbackBackgroundColor - Fallback background color. It is used if backgroundColor and
 *   priorityBackgroundColor are not present
 * @returns An object containing HTML styles attributes and values
 */
export function computeCellStyle(
  backgroundColor: string = undefined,
  textColor: string = undefined,
  textStyle: string[] = [],
  textAlign = 'left',
  priorityBackgroundColor: string = undefined,
  fallbackBackgroundColor: string = undefined,
  darkMode = false,
): {
  backgroundColor: string;
  color: string;
  fontWeight: string;
  fontStyle: string;
  textDecoration: string;
  textAlign: string;
} {
  const hasPriorityColor = !_.isUndefined(priorityBackgroundColor);
  const modeBasedTextColor = darkMode ? DEFAULT_TEXT_COLOR_DARK : DEFAULT_TEXT_COLOR_LIGHT;
  const appliedTextColor = textColor ? textColor : modeBasedTextColor;
  let noPriorityBackgroundColor;
  if (!_.isUndefined(backgroundColor)) {
    noPriorityBackgroundColor = backgroundColor;
  } else if (!_.isUndefined(fallbackBackgroundColor)) {
    noPriorityBackgroundColor = fallbackBackgroundColor;
  } else {
    noPriorityBackgroundColor = darkMode ? DEFAULT_BACKGROUND_DARK : DEFAULT_BACKGROUND_LIGHT;
  }

  const resultBackgroundColor = hasPriorityColor ? priorityBackgroundColor : noPriorityBackgroundColor;

  const blackOrWhite = tinycolor(priorityBackgroundColor).isDark() ? '#fff' : '#000';

  // check readability when we have metric color (priorityBackgroundColor) and overwrite text color if necessary
  const resultTextColor =
    hasPriorityColor && !isReadableColor(priorityBackgroundColor, appliedTextColor) ? blackOrWhite : appliedTextColor;

  return {
    backgroundColor: resultBackgroundColor,
    color: resultTextColor,
    fontWeight: _.includes(textStyle, 'bold') ? 'bold' : 'normal',
    fontStyle: _.includes(textStyle, 'italic') ? 'italic' : 'normal',
    textDecoration: _.filter(textStyle, (textDecoration) =>
      _.includes(['overline', 'line-through', 'underline'], textDecoration),
    ).join(' '),
    textAlign,
  };
}

/**
 * Cached version of {@code tinycolor.isReadable}. The original function is not particularly slow but when calling
 * it many times in tables with thousands of cells the cache improves performance.
 * @param backgroundColor - The background color.
 * @param textColor - The text color.
 * @return True if the text is readable on the specified background
 */
function isReadableColor(backgroundColor: string, textColor: string): boolean {
  const cacheKey = backgroundColor + textColor;
  let isReadable = isReadableColorCache.get(cacheKey);
  if (_.isUndefined(isReadable)) {
    isReadable = tinycolor.isReadable(backgroundColor, textColor);
    isReadableColorCache.set(cacheKey, isReadable);
  }
  return isReadable;
}

/**
 * Computes a foreground that contrasts with that color so that it is readable.
 *
 * @param backgroundColor - The background color. Default to white if not specified.
 * @param darkMode - true if dark mode is used to render the application
 * @return Styles for the background and foreground colors
 */
export function computeCellColors(
  backgroundColor: string,
  darkMode: boolean,
): {
  backgroundColor: string;
  color: string;
} {
  const defaultBackgroundColor = darkMode ? DEFAULT_BACKGROUND_DARK : DEFAULT_BACKGROUND_LIGHT;
  backgroundColor = backgroundColor ?? defaultBackgroundColor;
  const defaultTextColor = darkMode ? DEFAULT_TEXT_COLOR_DARK : '#000';
  return {
    backgroundColor,
    color: isReadableColor(backgroundColor, defaultTextColor) ? defaultTextColor : '#fff',
  };
}

/**
 * Gets the striped color based on the current state of isTableStriped and the row index passed in
 */
export function getStripedColor(isStriped: boolean, rowIndex: number, darkMode: boolean): string | undefined {
  const stripedCellColor = darkMode ? STRIPED_CELL_COLOR_DARK : STRIPED_CELL_COLOR;
  return isStriped && rowIndex % 2 === 0 ? stripedCellColor : undefined;
}

export function hasNumericAndStringItems(items: { itemType: string }[], itemType: ITEM_TYPES) {
  return _.chain(items)
    .filter((item) => item.itemType === itemType)
    .partition((signal) => isStringSeries(signal))
    .every((group) => !_.isEmpty(group))
    .value();
}

export function getMostReadableIconType(background = '#fff') {
  const mostReadable = tinycolor.mostReadable(background, [ANALYSIS_COLORS.DARK_PRIMARY, ANALYSIS_COLORS.LIGHT_COLOR]);
  return mostReadable.toString() === ANALYSIS_COLORS.DARK_PRIMARY ? 'theme' : 'theme-light';
}

export function copyToClipboard(tableBuilderRef: any) {
  try {
    if (!tableBuilderRef) {
      errorToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_EMPTY' });
    } else {
      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(tableBuilderRef);
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      successToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_SUCCESS' });
    }
  } catch (err) {
    errorToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_ERROR' });
  }
}

/**
 * Finds the size of the earliest parent node of the given node with the given type
 * @param node
 * @param type
 * @returns The height and width of the parent node, or undefined
 */
export function findParentSize(node: HTMLElement, type: string): { height: number; width: number } {
  let parentNode: HTMLElement = node?.parentElement;
  while (parentNode?.parentElement && parentNode.nodeName !== type) {
    parentNode = parentNode.parentElement;
  }
  return parentNode ? { height: parentNode.clientHeight, width: parentNode.clientWidth } : undefined;
}

/**
 * Utility method that determines if a given table column is a text column
 * @param column table column
 * @returns True if the given column is a text column, false otherwise
 */
export const isTextColumn = (column: TableColumn) =>
  _.isUndefined(column.style) ? false : ['assets', 'string', 'fullpath'].includes(column.style);
