import { CkEditorInstanceId, getCKEditorInstance, setHtmlCKEditor } from '@/annotation/ckEditor.utilities';

/**
 * Gets the data from CKEditor
 */
export function getHtmlReportEditor() {
  const ckeditorInstance = getCKEditorInstance('reportEditor');
  if (!ckeditorInstance) {
    return;
  }
  return ckeditorInstance.getData();
}

/**
 * Gets the editor scroll offset
 *
 * @returns the current scroll offset
 */
export function getScrollOffset() {
  const editorMain = document.querySelector('.ck-editor__main');
  return editorMain?.scrollTop ?? 0;
}

/**
 * Sets the editor scroll offset
 *
 * @param offset - a scroll offset
 */
export function setScrollOffset(offset: number) {
  // Setting the scroll offset right after a change to the document can sometimes cause the set to be eaten by
  // something CK does.
  setTimeout(() => {
    const editorMain = document.querySelector('.ck-editor__main');
    if (editorMain) {
      editorMain.scrollTop = offset;
    }
  }, 1);
}

/**
 * Sets the document html of the report when in view mode. In view mode the document is displayed in a simple
 * div. In edit mode, the document displays in the editor.
 *
 * @param {string} document - the html to set as the report view
 */
export function setReportViewHtml(document: string) {
  setHtmlCKEditor(document, 'reportEditor');
}

// CK screams and dies if you try to set the selection (cursor) to an invalid location. This checks the path of
// the position through the view and makes sure there is a valid element there.
export function isPositionValid(position: Position) {
  const path = position.path;
  if (!path.length) {
    return false;
  }

  let currentChild = getCKEditorInstance('reportEditor')?.model.document.getRoot();
  if (!currentChild) return false;
  for (let i = 0; i < path.length; i++) {
    const pathValue = path[i];
    currentChild = currentChild.getChild(pathValue);
    if (currentChild === null && pathValue > 0 && i === path.length - 1) {
      return false;
    }
  }

  return true;
}

export function setCursorPosition(cursorPosition: any) {
  if (isPositionValid(cursorPosition)) {
    getCKEditorInstance('reportEditor')?.model.change((writer) => {
      writer.setSelection(cursorPosition);
    });
  }
}

export function getCursorPosition() {
  return getCKEditorInstance('reportEditor')?.model.document.selection.getFirstPosition();
}

export function executeCommand<T extends { command: string; ckEditorInstanceId: CkEditorInstanceId }>({
  command,
  ckEditorInstanceId,
  ...rest
}: T): void {
  getCKEditorInstance(ckEditorInstanceId)?.execute(command, rest);
}
